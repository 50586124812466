import { IconoirFilterAlt, IconoirHelpCircle, IconoirGridPlus, IconoirGridMinus, IconoirCopy, IconoirUndo, IconoirPlus, IconoirNavArrowDown, IconoirMoreVert, IconoirEditPencil, MotifActionIcDelete24px, IconoirXmark, IconoirWarningTriangle, IconoirTrash, IconoirCheckCircle, IconoirSend, IconoirReply, IconoirCheck } from '@ey-xd/motif-icon';

export const MOTIF_ICONS = {
    IconoirFilterAlt: IconoirFilterAlt,
    IconoirHelpCircle: IconoirHelpCircle,
    IconoirGridPlus: IconoirGridPlus,
    IconoirGridMinus: IconoirGridMinus,
    IconoirCopy: IconoirCopy,
    IconoirUndo: IconoirUndo, 
    IconoirPlus: IconoirPlus, 
    IconoirNavArrowDown: IconoirNavArrowDown,
    IconoirMoreVert: IconoirMoreVert,
    MotifActionIcDelete24px: MotifActionIcDelete24px,
    IconoirEditPencil: IconoirEditPencil,
    IconoirXmark: IconoirXmark,
    IconoirWarningTriangle: IconoirWarningTriangle,
    IconoirCheck: IconoirCheck,
    IconoirTrash: IconoirTrash,
    IconoirCheckCircle: IconoirCheckCircle,
    IconoirSend: IconoirSend,
    IconoirReply: IconoirReply
}