<div class="layout-container">
  <div>
    <div class="layout-project-card">
      <div>
        <h3 class="header">Workspace Listing</h3>
        <sub class="sub-header">See all your current projects in one place.​</sub>
      </div>
      <div *ngIf="hasAddWorkspaceEntitlement(['AW'])">
        <button class="add-project" motifButton color="primary" size="medium" tooltip-color="primary" placement="auto"
          [hideIconClose]="true" [hideArrow]="false" 
          motif-tooltip="Enables you to create a new project. Following inputs are required: Client name, Project name, Industry, City, Country."
          (click)="OpenCreateWorkspaceModel()" i18n="Home Add workspace button | Add Workspace button@@homeAddWSBtn">
          Add Project
        </button>
      </div>
    </div>
    <hr class="bottom-border">
  </div>
  <div class="projects-filter">
    <label for="projects-filter-radio-group">Projects:</label>
    <motif-radio-button-group
      aria-label="undefined"
      role="radiogroup"
      id="projects-filter-radio-group"
      aria-labelledby="projects-filter-radio-group"
      aria-describedby="projects-filter-radio-group"
    >
      <motif-radio-button (change)="getProjects($event)" checked value="all">
        All
      </motif-radio-button>
      <motif-radio-button (change)="getProjects($event)" value="active">
        Active
      </motif-radio-button>
      <motif-radio-button (change)="getProjects($event)" value="inactive">
        In-Active
      </motif-radio-button>
    </motif-radio-button-group>
  </div>
  <div>
    <div class="project-filter-message" *ngIf="!workspaces.length && isWorkspaceLoaded">
      <motif-inline-message [type]="'warning'">{{selectedProjectsMessage}}</motif-inline-message>
    </div>
    <div class="layout-card">
      <div *ngFor="let workspace of workspaces" class="layout-workspace">
        <motif-card orientation="horizontal" cardVariant="card-border" [ngClass]="workspace.isProvisioned ? 'border-active' : 'border-inactive'">
          <motif-card-header>
              <div class="motif-col-xs-3 col-xs-3 motif-middle-xs">
                <span class="motif-h3 card-workspace-name" [title]="workspace.name"
                  [ngClass]="workspace.isProvisioned?'cursor: pointer':'disable-card'"
                  (click)="workspace.isProvisioned && setSelectedWorkspace(workspace)">{{workspace.name}}
                </span>
              </div>
              <div class="motif-col-xs-1 col-xs-1 motif-middle-xs motif-end-xs">
                <div motifDropdown placement="bottom-right">
                  <button motifIconButton aria-label="icon-button" motifDropdownToggle id="motifExampleDropdown1"
                    class="background-none">
                    <motif-icon [iconFunction]="iconFunction('IconoirMoreVert')"></motif-icon>
                  </button>
                  <div motifDropdownMenu class="motif-dropdown-menuCss">
                    <button motifDropdownItem class="edit-btn"
                      [ngClass]="{'disable-icon': !hasWorkspaceEntitlement(workspace.guid,'EW')}"
                      (click)="hasWorkspaceEntitlement(workspace.guid, 'EW')? OpenUpdateWorkspaceModel(workspace.guid): false">
                      <motif-icon size="medium" [iconFunction]="iconFunction('IconoirEditPencil')"></motif-icon>
                      <span>Edit</span>
                    </button>
                    <button motifDropdownItem
                      [ngClass]="{'disable-icon': !hasWorkspaceEntitlement(workspace.guid,'DW')}"
                      (click)="hasWorkspaceEntitlement(workspace.guid, 'DW')? OpenDeleteConfirmModal(workspace): false">
                      <motif-icon size="small" class="workspace-delete-icon" [iconFunction]="iconFunction('IconoirTrash')"></motif-icon>
                      <span>Delete</span>
                    </button>
                  </div>
                </div>
              </div>
          </motif-card-header>
          <motif-card-body class="layout-card-body" [ngClass]="workspace.isProvisioned?'':'disable-card'">
            <div class="clientname-card">
              {{workspace.clientName}}
            </div>
          </motif-card-body>
          <motif-card-footer class="layout-card-footer" [ngClass]="workspace.isProvisioned?'':'disable-card'">
            {{workspace.country.name}} | Last modified on {{workspace.modifiedOn | date : 'MMM dd, yyyy' }}
          </motif-card-footer>
        </motif-card>
      </div>
    </div>
  </div>
</div>