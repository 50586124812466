<motif-modal-title>
    <h5 class="modal=title"> {{title}} </h5>
    <button id="close-btn" class="close-btn" motifIconButton aria-label="Close modal" size="medium" [motif-modal-close]="false">
      <motif-icon class="motif-modal-header-close-icon" size="20" [iconFunction]="closeIcon()"></motif-icon>
    </button>
  </motif-modal-title>
  <motif-modal-content>
    <div class="modal-body text-left">
  
      <span [innerHtml]="content"></span>
    </div>
  </motif-modal-content>
  <motif-modal-actions>
    <button motifButton motif-modal-close color="secondary" size="medium" aria-label="Cancel">{{cancelBtnName}}</button>
    <button (click)="deleteWorkspace()" motifButton color="warn" size="medium" [motif-modal-close]="true" aria-label="Yes, Confirm"
      >{{confirmBtnName}}</button>
  </motif-modal-actions>