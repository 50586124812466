import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { FunctionalArea } from '../questionairrelibrary/questionMetadata';
import { SettingsProvider } from 'src/app/settingsProvider';
import { environment } from 'src/assets/client-config';

@Injectable({
  providedIn: 'root'
})
export class ReadinessScoreService {
  public workspaces = new BehaviorSubject<any | null>(null);
  private readinessApiUrl =
    environment.apiUrl + '/api/Readiness/';

  constructor(private http: HttpClient, private settings: SettingsProvider) {}

  getFunctionalAreaList(): Observable<FunctionalArea[]> {
    return this.http
      .get<FunctionalArea[]>(
        this.readinessApiUrl + 'WeightedScoreByFunctionalArea'
      )
      .pipe(tap(data => data));
  }

  saveCalculatedReadinessScore() {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http
      .post<FunctionalArea[]>(
        this.readinessApiUrl + 'SaveReadinessWeightedScores',
        [],
        options
      )
      .pipe(
        catchError(this.handleError<FunctionalArea[]>('saveReadinessScore'))
      );
  }

  saveReadinessScore(functionalAreaList: FunctionalArea[]) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http
      .post<FunctionalArea[]>(
        this.readinessApiUrl + 'FunctionalAreaReadinessScore',
        functionalAreaList,
        options
      )
      .pipe(
        catchError(this.handleError<FunctionalArea[]>('saveReadinessScore'))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result);
    };
  }
}
