export const Labels = {
  // Load/Create/Update/Delete workspace errors
  UnExpectedErrorWSUpdate:
    'An unexpected error occurred while updating the project. Please try again.', // Renamed workspace to project Story#269269
  UnExpectedErrorWSCreate:
    'An unexpected error occurred while creating the project. Please try again.', // Renamed workspace to project Story#269269
  UnExpectedErrorWSDelete:
    'An unexepected error occurred while deleting the project.', // Renamed workspace to project Story#269269
  ServerError: 'An unexpected error has occurred. Please contact support team.',
  ServerErrorLoadingCountry:
    'An unexpected error while loading the countries. Please try again.',
  ServerErrorLoadingIndustry:
    'An unexpected error while loading the industries. Please try again.',
  UnExpectedErrorWSLoad:
    'An unexpected error occurred while loading the projects.', // Renamed workspace to project Story#269269
  UnExpectedErrorQuickLinksLoad:
    'An unexpected error occurred while loading the quick links.',
  ServerErrorUserEntitlements:
    'An unexpected error while loading the user entitlements. Please try again.',

  ServerErrorDeleteActivity: 'An unexepected error while deleted the activity.',
  ServerErrorOnSave: 'An unexpected error while saving. Please try again.',
  ServerErrorOnLoad: 'An unexpected error while loading. Please try again.',
  ServerErrorOnUpdate: 'An unexpected error while updating. Please try again.',
  ValidationError:"Validation failed. Spaces at the beginning and end, as well as the characters '! @ ^  *  + =' are not allowed. Please rectify the input to proceed with saving.",
  // Home page - Create workspace modal
  ModalTitleAddWS: 'Add Project', // Renamed workspace to project Story#269269
  ModalCloseBtnName: 'Cancel',
  ModalSaveBtnName: 'Add Project', // Renamed workspace to project Story#269269
  ModalCreateWSSuccess: 'Project added successfully.', // Renamed workspace to project Story#269269
  ModalUpdateWSSuccess: 'Project updated successfully.', // Renamed workspace to project Story#269269
  ModalDeleteWSSuccess: 'Project deleted successfully.', // Renamed workspace to project Story#269269
  ModalTitleUpdateWS: 'Edit Project', // Renamed workspace to project Story#269269
  ModalUpdateBtnName: 'Edit Project', // Renamed workspace to project Story#269269
  inactiveProjects: 'At the moment, there are no inactive projects in your workspace.',
  activeProjects: 'At the moment, there are no active projects in your workspace.',
  SaveBtnName: 'Save',

  // Home page delete workspace modal
  ModalTitleDeleteWS: 'Delete Project',  // Renamed workspace to project Story#269269
  ModalDeleteWSConfirmMsg: 'Are you sure you want to delete this project',  // Renamed workspace to project Story#269269
  ModalDeleteConfirmBtnName: 'Delete',
  ModalDeleteBtnName: 'Delete question',
  ModalDeleteCancelBtnName: 'Cancel',

  // Copy engagement
  EngagementCopiedSuccessfully: 'Questionnaire setup copied successfully',
  ModalCopyEngagementError: 'An unexpected error while copying the selected engagement. Please try again.',
  CopyEngagementTitle: 'Copy from project',
  CopyEngagementModalConfirm: 'Copy',
  CopyModalContent: `<div>
  You can override all the data from this project with data from another project. To do so, select the project you want to use in place of this current one from the list below and click Confirm.</br></br>
​  If you choose to override the data for this project, you will lose all current project changes and the data for this project will be replaced with data from the project selected below.​
                    </div>`,

  // Create Question Modal
  ModalCreateQuestionError:
    'An unexpected error while saving the question. Please try again.',
  ModalCreateQuestionLoadFAError:
    'An unexpected error while loading functional areas',

  DefaultmultiselectLabel: 'Choose',

  // Question Library Page
  QuestionAddedSuccesfullToLibrary:
    'Question added successfully to question library',
  QuestionsAddedSuccessfullyToSetup:
    'Questions added successfully to questionnaire setup',
  ModalSaveQuestionsError:
    'An unexpected error while saving the question(s). Please try again.',
  AddQuestionConfirmationTitle: 'Add to questionnaire setup',
  AreYouSure: 'Are you sure you want to add these "',
  QuestionToQuestionnaire: '" questions to Questionnaire Setup?',
  QuesLibGridHeaderQuestion: 'Question',
  QuesLibGridHeaderFunctionalArea: 'Functional Area',
  QuesLibGridHeaderExchangeDestination: 'Destination',
  QuesLibGridHeaderAction: 'Actions',
  QuesLibTooltipMessage:
    'Question has already been added to questionnaire setup',
  QuesLibPopularityScoreToolTip: 'Number of times question has been used',
  QuestionsDeletedSuccessfully: 'Question(s) deleted successfully',
  QuestionDeletedSuccessfully: 'Question deleted successfully',
  AreYouSureDelete: 'Are you sure you want to delete these ',
  QuestionsAreYouSureLabel: ' questions?',
  ConfirmDeleteBtn: 'Confirm Delete',
  QuesLibGridHeaderFrequency: 'Frequency',
  // QuesLibGridHeaderFrequency: 'Frequently selected questions',
  QuesLibCircledBTooltipMessage:
    'This is a benchmarked question. Please avoid changing the substance of the question. If necessary, add a new question.',
  // Questionaire Setup page
  CollapseAll: 'Collapse All',
  ExpandAll: 'Expand All',
  HideOutOfScope: 'Hide out-of-scope items',
  ShowOutOfScope: 'Show out-of-scope items',
  QuestionDeletedSuccesfullToFA:
    'Question deleted successfully from functional area',

  QuestionAddesSuccesfullToFA: 'Question added successfully to functional area',
  QuestionUpdatedSuccessfull: 'Question updated successfully.',
  ModalTitleDeleteQuestion: 'Delete question',
  ModalTitleDeleteQuestions: 'Delete questions',
  QuestionDeleteConfirm: 'Are you sure you want to delete this question? <br /> Once deleted, retrieval is not possible.',
  SelectedQuestionsDeleteConfirm: 'Are you sure you want to delete selected questions? <br /> Once deleted, retrieval is not possible.',
  AddQuestionModal: 'Add question',
  FunctionalAreaAddedSuccesfull: 'Functional area added successfully',
  FunctionalAreaUpdatedSuccesfull: 'Functional area renamed successfully',
  AddFunctionalAreaModal: 'Add functional area',
  AddFunctionalAreaWarning: "Click 'Add Questions' to include questions in 'Functional Area'. The functional area won't appear under questionnaire setup until you add at least one question.",
  UpdateFunctionalAreaModal: 'Update functional area',
  UpdateQuestionModal: 'Update question',
  UpdateHeatmapModal: 'Update Key recommendations/actions',
  EditFunctionalAreaDetails: 'Edit functional area details',
  ResetModalTitle: 'Reset',
  // tslint:disable-next-line:max-line-length
  ResetModalContent: `<div>
                      All the data (your changes to base case) from this engagement will <br />
                       be Deleted and reset to standard. Are you sure you want to continue?<br />
                      <br>
                        1. Click on "Confirm" button to reset the data, all changes will be lost<br />
                        2. Click on "Cancel" to dismiss this box
                      </div>`,
  ResetModalConfirm: 'Yes, Confirm',
  ResetSuccessful: 'Data Reset successfully',
  ResetFailed:
    'Data Reset not successful due to an unexpected error. Please try again',
  ResetWorkspace: 'Reset Project',  // Renamed workspace to project Story#269269

  // Questionaire Client view
  QuestionLabel: 'Question',
  ShowAllQuestionLabel: 'Show all questions',
  HideAllQuestionLabel: 'Hide all questions',
  nextLabel: 'Save & Next',
  addToMilestone: 'Add to milestone',
  saveLabel: 'Save',
  addLabel: 'Add',
  selectOneLabel: 'Select One',
  timings1to4Label: '1 to 4 months',
  timings5to8Label: '5 to 8 months',
  timings8to12Label: '9 to 12 months',
  timingMorethan12Label: 'More than 12 months',
  timingAlreadyCompleted: 'Already completed',
  QuestionLoadError: 'An unexepected error while loading questionnaire.',
  ChangesSavedSuccessful: 'Changes saved successfully.',
  CompletedAllAnswersTitle: 'Questionnaire completed',
  CompletedAllAnswers: 'You have completed all answers in questionnaire.',
  okLabel: 'Ok',
  ResponseErrorES: 'Please change the response to Yes/No/Partially to enable Executive Summary',
  TooltipToCheckES: 'Check this box to add this point to the Executive Summary',
  TooltipToUncheckES: 'Uncheck this box to remove this point from the Executive summary',
  TooltipToCheckHeatMap: 'Check this box to add this point to the Heatmap/Bubble chart',
  TooltipToUncheckHeatMap: 'Uncheck this box to remove this point from the Heatmap/Bubble chart',
  DisableES: "Setting the response to N/A will disable the Executive Summary, and any entered information will be lost. Do you wish to proceed?",

  SendToBenchmarkModalTitle: 'Confirm: Send to benchmarking',
  SendToBenchmarkModalContent:
    'Are you sure you want to send the data to benchmarking?',
  SendToBenchmarkSuccessful:
    'Benchmarking data saved successfully',
  RadioCompareSameIndustry: 'Compare within same industry',
  RadioCompareAllIndustry: 'Compare with all industries',

  // Create Functional Area
  ModalCreateFunctionalAreaError:
    'An unexpected error while saving the functional area. Please try again.',

  // Unsaved changes Popup
  ModalUnsavedTitle: 'Unsaved changes',
  ModalAlertTitle: 'Alert',
  ModalUnsavedContent: `
                        You have unsaved changes. To save changes:<br />
                        1. Please click Cancel button to dismiss this box.<br />
                        2. Click on Save button before moving ahead.
                      `,
  ModalUnsavedContentQuestion: `
                      You have unsaved changes. To save changes:<br />
                      1. Please click Cancel button to dismiss this box.<br />
                      2. Click on Save button before moving ahead.
                    `,
  ModalUnsavedConfirmBtnName: 'Confirm',
  ModalUnsavedCancelBtnName: 'Cancel',

  // Chart Labels
  EffortHigh: 'High',
  EffortMedium: 'Medium',
  EffortLow: 'Low',
  ImpactHigh: 'High',
  ImpactMedium: 'Medium',
  ImpactLow: 'Low',
  ResponseYes: 'Yes',
  ResponseNo: 'No',
  ResponsePartially: 'Partially',
  ResponseNA: 'NA',

  // Create Note modal
  ModalTitleAddNote: 'Add Note',
  ModalSaveBtnNote: 'Add Note',
  ModalCreateNoteSuccess: 'Note added successfully.',
  ModalUpdateNoteSuccess: 'Note updated successfully.',
  ModalDeleteNoteSuccess: 'Note deleted successfully.',
  ModalTitleUpdateNote: 'Update Note',
  ModalUpdateBtnUpdate: 'Update Note',
  ModalTitleDeleteNote: 'Confirm delete note',
  ModalContentDeleteNote: 'Are you sure you want to delete this note?',
  ModallinkShowMore: 'Show More',
  ModalLinkShowLess: 'Show Less',
  NoteAddedSuccesful: 'Note added successfully.',

  // Add Activity
  AddActivity: 'Add milestone',
  UpdateActivity: 'Edit milestone',
  ModalActivityDelete: 'Confirm delete milestone',
  ModalContentDelteActivity: 'Are you sure you want to delete this milestone?',
  ActivityNotStarted: 'Not started',
  ActivityInprogress: 'In progress',
  ActivityCompleted: 'Completed',


  AlertActivityDeleteSuccessContent: 'Milestone deleted successfully.',
  AlertActivityAddedSuccesContent: 'Milestone added successfully.',
  AlertActivityUpdatedSuccessContent: 'Milestone updated successfully.',

  // User Management
  UserGridColumnName: 'Name',
  UserGridColumnEmail: 'Email ID',
  UserGridColumnWorkspaces: 'Project(s)', // Renamed workspace to project Story#269269
  UserGridColumnAccessRights: 'Role',
  UserGridColumnActions: 'Actions',

  AddUser: 'Add User',
  EditUser: 'Edit User',
  UserNoPrivelegeSuperAdmin: 'You Do not have privilege to edit Super Admin',
  ConfirmUserDelete: 'Delete User',
  DeleteUserModalContent: 'Are you sure you want to delete this user?',
  ConfirmDelete: 'Confirm delete',
  DeletingAllQuestionsAlert: 'Deleting all questions will also delete the functional area from setup and retrieval is not possible. Do you want to continue?',
  UserRoleDeleteSuccess: 'User role deleted successfully.',
  UserRoleDeleteFail: 'An unexepected error while deleting the User Role.',
  UserRoleAddSuccess: 'User/Role added successfully.',
  UserRoleUpdateSuccess: 'User updated successfully.',

  // Dashboard/Create owner modal
  CreateOwnerModalTitle: 'Add Functional Area Owner',
  EditOwnerModalTitle: 'Edit Functional Area Owner',
  OwnerSaveSuccess: 'Owner details saved successfully.',
  OwnerSaveFail: 'An unexepected error while deleting the User Role.',
  AutoSaveMessage: 'Changes auto saved',
  // Heatmap/Bubble Chart
  HeatmapChartTitle: 'Heatmap/Bubble Chart',
  HeatmapChartLowLabel: 'Low',
  HeatmapChartMediumLabel: 'Medium',
  HeatmapChartHighLabel: 'High',
  HeatmapChartXAxesLabel: 'Effort',
  HeatmapChartYAxesLabel: 'Impact',
  HeatmapChartTooltipQText: 'Question ID: ',
  HeatmapChartTooltipNText: ', Notes : ',
  HeatmapGridIDcolHeader: 'ID',
  HeatmapGridHeatmapIDcolHeader: 'Heatmap ID',
  HeatmapGridFAcolHeader: 'Functional area',
  HeatmapGridQIdcolHeader: 'Question ID',
  HeatmapGridNotecolHeader: 'Summarised text for Heatmap/Bubble chart',
  HeatmapGridObservationcolHeader: 'Observation',
  HeatmapGridRecommendationcolHeader: 'Recommendation',
  HeatmapGridActioncolHeader: 'Edit',

  // Quick links
  JapanTemplateTitle: 'Templates',
  CollpaseTemplateTitle: 'Collpase templates',
  ExpandTemplateTitle: 'Expand templates',
  JapanPPTTitle: 'Japan standard template for IPO readiness report',
  ClickToSeeTitle: 'click to see the templates available',

  //Privacy Policy

  modalTitle: 'Privacy Notice',
  modalBtnName: 'I accept',
  privacyPolicySuccess: 'Privacy policy accepted successfully',
  privacyPolicyError: 'Something went wrong. Privacy policy not accepted',

  //questionnaire setup
  Expand: 'Expand the selected functional area',
  Collapse:'Collapse the selected functional area',

  // subTabs
  exportTitleTooltip : 'Please select the desired template first to proceed with exporting the report.',

  //Executive summary
  modalTitleQuestion: 'Question',
  modalTitleRecommendation: 'Recommendation',
  modalClose: 'Close',
  CriticalityUpdatedSuccessfull: 'Criticality updated successfully.',
};
