<motif-modal-title>
  <h3 [ngClass]="title=='Delete question' || title=='Delete questions' ? 'delete-question' : '' " >
     <motif-icon *ngIf="title === 'Alert'" class="alert-icon" size="20" [iconFunction]="iconFunction('IconoirWarningTriangle')"></motif-icon> {{title}} </h3>

  <button id="close-btn" class="close-btn" motifIconButton aria-label="Close modal" size="medium" (click)="confirmCancel()" [motif-modal-close]="false">
    <motif-icon class="motif-modal-header-close-icon" size="20" [iconFunction]="iconFunction('IconoirXmark')"></motif-icon>
  </button>
</motif-modal-title>
<motif-modal-content  [ngClass]="title=='Delete question'? 'delete-content':'' ">
  <div class="modal-body text-left">
    
    <span [class.warning]="content === deletingAllQuestionsAlert" [innerHtml]="content"></span>
  </div>
</motif-modal-content>
<motif-modal-actions>
  <button (click)="confirmCancel()" motifButton motif-modal-close color="secondary" size="medium" aria-label={{cancelBtnName}}
    >{{cancelBtnName}}</button>
    <button (click)="closePopup()" motifButton color="primary" size="medium" [ngClass]="{'delete-button': isDeleteButton}" [motif-modal-close]="true"
    aria-label={{confirmBtnName}}>{{confirmBtnName}}</button>
</motif-modal-actions>