import { HttpClient } from '@angular/common/http';
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { ModalSizes, MotifModal, MotifModalRef } from '@ey-xd/ng-motif';
import { BehaviorSubject, firstValueFrom, timer } from 'rxjs';
import { environment } from 'src/assets/client-config';
import { RenewSessionComponent } from './components/renew-session/renew-session.component';
import { MsalService } from '@azure/msal-angular';

@Injectable({
    providedIn: 'root'
})
export class SessionService {
   // active session interval
    activeSession:any;
    //Declared as Observables 
    public inactiveSessionExpiry = new BehaviorSubject<number>(0);
    public sessionId = new BehaviorSubject<string>(null);
    //private timer variables for 
    private mandatorySecondsTimer: number = 0;
    private inactivitySecondsTimer: number = 0;
    private activeTimeRemaining: number = 0;
    private timer: any;
    private render: Renderer2;
    private timerWorker: Worker | undefined;

    public modalRef?: MotifModalRef<any>;
    constructor(private motifModal: MotifModal, private http: HttpClient, private msal: MsalService, private renderFactory: RendererFactory2) {
        this.render = this.renderFactory.createRenderer(null, null);
     }

    //set new session on app load
    startSession() {
        return this.http.post<any>(environment.apiUrl + environment.loginUrl, {}).subscribe(result => {
            if (result) {
                this.sessionId.next(result.sessionId);
                this.inactiveSessionExpiry.next(result.inActiveSessionExpiryInSeconds);   // reused whenever the active timer is reset due to api call or renew session
                this.inactivitySecondsTimer = result.inActiveSessionExpiryInSeconds;    // to set active timer value
                this.mandatorySecondsTimer = result.mandatorySessionTimeOutInSeconds;    // to set mandatory timer value
                this.startActiveSessionTimer();
                this.startMandatorySessionTimer();
                this.startWatching(200, this.resetSession.bind(this));
            }
        });
    }

    resetSession() {
        if (this.activeTimeRemaining > 60) {
            if(localStorage.length === 0) {
                window.location.reload();
            }
            this.updateSession();
        }
    }

    startWatching(idleTime: number, resetFn: () => void) {
        this.resetTimer();
        this.attachEvents(resetFn);
        this.startTimer(idleTime, resetFn);
    }

    resetTimer() {
        if (this.timer) clearTimeout(this.timer);
    }

    startTimer(idleTimeout: number, resetFn: () => void) {
        this.timer = setTimeout(() => {
            resetFn();
        }, idleTimeout);
    }

    private attachEvents(resetFn: () => void) {
        ['click', 'mousemove', 'keypress'].forEach(evt => {
            this.render.listen('document', evt, () => {
                if (this.activeTimeRemaining > 60) {
                    this.resetTimer();
                    this.startTimer(1000, resetFn);
                }
            });
        });
    }

    //renew session 
    renewSession() {
        return this.http.post<any>(environment.apiUrl + environment.renewSession, null).subscribe(result => {
            if (result) {
                this.startActiveSessionTimer();
            }
        });
    }
    //update session on api call activities
    updateSession(){
        return this.http.get<any>(environment.apiUrl + environment.updateSession).subscribe(result=>{
            this.startActiveSessionTimer();
        });
    }
    //logout session
    logoutSession() {
        return this.http.get(environment.apiUrl + environment.logout, { responseType: 'text' }).subscribe(result => {
            if (result) {
                this.msal.logout();
                sessionStorage.clear();
                localStorage.clear();
            }
        });
    }
    //to start active session timer
    startActiveSessionTimer() {
        if (this.timerWorker) {
            this.timerWorker.terminate();
        }
        this.timerWorker = new Worker(new URL('./timer.worker', import.meta.url));
        this.timerWorker.postMessage(this.inactivitySecondsTimer);
        this.timerWorker.onmessage = ({ data }) => {
            if (typeof data === 'number') this.activeTimeRemaining = data; 
            if (data === 'confirm') {
                this.modalRef = this.motifModal.open(RenewSessionComponent, { size: ModalSizes.L, hasBackdrop: true, disableClose: true });
            }
            if (data === 'timeout') {
                this.logoutSession();
                this.motifModal?.closeAll();
            }
            if (typeof data === 'number') this.inactiveSessionExpiry.next(data);
        }

    }
    //to start mandatory session timer
    startMandatorySessionTimer() {
        const mandatorySession = timer(200, 1200);
        mandatorySession.subscribe(val => {
            const mandatoryTimeRemaining = this.mandatorySecondsTimer - val;
            if (mandatoryTimeRemaining == 0) {
                this.logoutSession();
                this.motifModal?.closeAll();
            }
        });
    }

    //security token
    async getSecurityToken(): Promise<any>{
        const token = await firstValueFrom(
            this.http.post(environment.apiUrl + '/api/Token/sk', null, { responseType: 'text' })
        );
        return token;    
    }

    setSessionStorage(key: string, value: any) {
        sessionStorage[key] = JSON.stringify(value);
    }

    getSessionStorage(key: string) {
        return JSON.parse(sessionStorage[key] || null);
    }

    clearSessionStorage(key: string) {
        sessionStorage.removeItem(key);
    }
}